<template>
    <div class="default-table" :class="adaptiveClass">
        <table class="table">
            <thead>
                <tr class="table__labelTr">
                    <th
                        v-for="column in headerColumns"
                        :key="column.id"
                        @click="cellClick(column)"
                        :style="'width:'+ (100 / (headerColumns.length + !hideActions)) + '%'"
                        :class="{
                            sorted: column.sort,
                            actions: column.showActions,
                        }"
                    >
                        <p class="typography typography--head table__label" :class="{ 'table__label--pointer': column.sort }">
                            {{ column.label }}
                            <img src="@/assets/img/sort.svg" v-if="column.sort" alt="svg" />
                        </p>
                    </th>
                    <th class="table__actions" v-if="!hideActions"></th>
                </tr>
                <tr v-if="fields">
                    <th v-for="(f, index) in fields" :key="index" class="table__fieldTh">
                        <SelectComponent
                            :route-value="valuesFromRoute ? f.by : false"
                            :searchable="f.isSearchable"
                            @clearSearch="clearSearch($event, f.by)"
                            @input="searchBySelect($event, f.by, f.options)"
                            v-if="f.type == 'select'"
                            :value="f.defaultValue"
                            :options="f.options"
                            :name="f.name"
                            :clearable="clearable"
                        />
                        <SearchComponent
                            :route-value="valuesFromRoute ? f.by : false"
                            :isBlurSearch="true"
                            v-if="f.type == 'search'"
                            :searchBy="f.by"
                            @change="searchByColumn"
                        />
                        <DatePickerComponent
                            v-if="f.type == 'data'"
                            :searchBy="f.by"
                            @input="getData($event)"
                            @clearDate="clearDate($event)"
                            type="date"
                            :route-value="valuesFromRoute ? f.by : false"
                            :clearable="clearable"
                        />
                    </th>
                </tr>
            </thead>
            <tbody v-if="!preloader">
                <tr v-for="item in sortedItems" :key="item.id">
                    <td v-for="k in headerColumns" :key="k.id">
                        <div :class="lineBrakeClass(cellContent(k, item))">
                            {{ cellContent(k, item) }}
                        </div>
                    </td>
                    <td v-if="!hideActions">
                        <div class="buttons">
                            <button class="btn edit" @dblclick.stop @click="update(item.id)" v-if="showUpdate && canUpdate(item)">
                              <span class="material-icons" :title="$t('Edit')" v-if="editIcon">edit</span>
                              <span class="material-icons" :title="$t('Show details')" v-if="visibilityIcon">visibility</span>
                            </button>
                            <button class="btn delete" @click="deleteItem(item)" v-if="showDelete">
                                <span class="material-icons" :title="$t('Edit')">delete</span>
                            </button>
                            <!-- <button
                                class="btn access"
                                @dblclick.stop
                                @click="access(item.id)"
                                v-if="!item.showAccess && showAccess && canAccess(item)"
                            >
                                <span class="material-icons">lock</span>
                            </button> -->
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
        <div class="empty" v-if="!items.length && preloader === false">
<!--            <h5 class="empty__title">{{ $t('components.ui.tables.tableMain.empty') }}</h5>-->
            <Empty />
        </div>
        <Preloader v-if="preloader"></Preloader>

        <Pagination v-if="meta.total" class="pagination" :value="pageNumber" :total="meta.total" :per-page="meta.per_page" @input="changePage"></Pagination>
    </div>
</template>

<script>
    import Pagination from "@/components/ui/tables/Pagination"
    import SelectComponent from "@/components/ui/forms/SelectComponent"
    import SearchComponent from "@/components/ui/forms/SearchComponent"
    import DatePickerComponent from "@/components/ui/forms/DatePickerComponent"
    import Empty from "../../Empty";
    import Preloader from "../Preloader";
    /*
import {mapGetters} from "vuex";
import SelectComponents from "@/components/ui/forms/SelectComponents"; */

    export default {
        name: "TableMain",
        components: { Pagination, SearchComponent, SelectComponent, DatePickerComponent, Empty, Preloader },
        props: {
            headerColumns: {
                required: false,
                type: Array,
                default() {
                    return [
                        {
                            id: 1,
                            label: this.$t('Name'),
                            attribute: "name",
                            sort: true,
                        },
                    ]
                },
            },
            preloader: {
                required: true,
                type: Boolean,
                default: false,
            },
            clearable: {
                required: false,
                type: Boolean,
                default: true
            },
            canRestore: {
                required: false,
                type: Boolean,
                default: false,
            },
            editIcon: {
                required: false,
                type: Boolean,
                default: true,
            },
            visibilityIcon:{
                required: false,
                type: Boolean,
                default: false,
            },
            hideActions: {
                required: false,
                type: Boolean,
            },
            fields: {
                required: false,
                type: Array,
                default: null,
            },
            items: {
                required: true,
                type: Array,
            },
            type: {
                required: false,
                type: String,
                default: "raw",
            },
            showUpdate: {
                required: false,
                type: Boolean,
                default: true,
            },
            showAccess: {
                required: false,
                type: Boolean,
                default: true,
            },
            valuesFromRoute: {
                required: false,
                type: Boolean,
                default: false
            },
            itemsMeta: {
                required: false
            },
            scroll: {
                required: false,
                type: String,
                default: null, // md, lg
            },
            showDelete: {
                required: false,
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                sort: {
                    key: "",
                    isAsc: false,
                },
                removeModal: false,
                removeId: 0,
                logModelValue: undefined,
                logVerbValue: undefined,
                logUsersValue: undefined,
                logDateValue: undefined,
                startDate: Date.now()
            }
        },
        computed: {
            adaptiveClass() {
                if (this.scroll) {
                    return 'adaptive--' + this.scroll
                }
                return false
            },
            pageNumber() {
                return this.itemsMeta.current_page
            },
            meta() {
                return this.itemsMeta;
            },
            sortedItems() {
                const list = this.items.slice()
                if (!!this.sort.key) {
                    list.sort((a, b) => {
                        if (typeof a[this.sort.key] == "number") {
                            let name1 = a[this.sort.key]
                            let name2 = b[this.sort.key]
                            if (this.sort.isAsc) {
                                return name1 > name2 ? 1 : name1 < name2 ? -1 : 0
                            } else {
                                return name1 < name2 ? 1 : name1 > name2 ? -1 : 0
                            }
                        } else {
                            return a[this.sort.key].localeCompare(b[this.sort.key]) * (this.sort.isAsc ? 1 : -1)
                        }
                    })
                }

                return list
            },
        },
        methods: {
            cellClick(column) {
                if (column?.urlAttribute) {
                    return column.sort ? this.sortBy(column.urlAttribute) : null
                }
                return column.sort ? this.sortBy(column.attribute) : null
            },
            lineBrakeClass(text) {
                if (!text || typeof text == "number") return ""
                let textTrim = text?.replace(/ +/g, " ").trim()
                let str = textTrim?.split(" ").length - 1
                if (str <= 2) {
                    return "word-wrap"
                }
                return ""
            },
            searchByColumn(value, searchBy) {
                if (value) {
                    this.$router.push({ query: Object.assign({}, this.$route.query, {[searchBy]: value}) }).catch(()=>{});
                } else {
                    this.$router.push({ query: Object.assign({}, this.$route.query, {[searchBy]: undefined}) }).catch(()=>{});
                }
                this.$router.push({ query: Object.assign({}, this.$route.query, {page: 1, sort_by: 'id'}) }).catch(()=>{});

                this.$emit('getItems', this.$route.query)
            },
            clearSearch(val, by){
                this.$router.push({ query: Object.assign({}, this.$route.query, {[by]: undefined}) }).catch(()=>{});
                this.$emit('getItems', this.$route.query)
            },
            searchBySelect(val, by, options) {
                this.$emit('getSearchProperty', by)
                if (val) {
                    this.$router.push({ query: Object.assign({}, this.$route.query, {[by]: val}) }).catch(()=>{});
                }
                else {
                    this.$router.push({ query: Object.assign({}, this.$route.query, {[by]: undefined}) }).catch(()=>{});
                }
                this.$router.push({ query: Object.assign({}, this.$route.query, {page: 1, sort_by: 'id'}) }).catch(()=>{});
                this.$emit('getItems', this.$route.query)
            },
            getData(v) {
                let value = this.helper.getDate(v)
                this.searchByColumn(value, 'date')
            },
            deleteItem(item){
                this.$emit('getItemForDelete', item)
            },
            clearDate(v){
                this.searchByColumn(v, 'date')
            },
            canUpdate(item) {
                return true
            },
            changePage(p) {
                if (this.isSearchPanel) {
                    this.$emit("changePage", p)
                }
                this.$router.push({ query: Object.assign({}, this.$route.query, {page: p }) }).catch(()=>{});
                this.$emit('getItems', this.$route.query)
            },
            changeSortable(arg) {
                if (!this.sortedItems[arg.moved.newIndex] || !arg["moved"]) {
                    return false
                }

                this.$emit("draggable", {
                    item: arg["moved"]["element"],
                    entity: this.sortedItems[arg.moved.newIndex],
                })
            },
            canAccess(item) {
                return true
            },
            sortBy(key) {
                if (this.$route.query?.sort_dir == 'asc') {
                    this.sort.isAsc = false
                }
                else {
                    this.sort.isAsc = true
                }
                this.sort.key = key;

                this.$router.push({ query: Object.assign({}, this.$route.query, { sort_by: key, page: 1 }) }).catch(()=>{})

                if (this.sort.isAsc) {
                    this.$router.push({ query: Object.assign({}, this.$route.query, { sort_dir: 'asc' }) }).catch(()=>{})
                }
                else {
                    this.$router.push({ query: Object.assign({}, this.$route.query, { sort_dir: 'desc' }) }).catch(()=>{})
                }
                this.$emit('getItems', this.$route.query)
            },
            access(id) {
                this.$emit("access", id)
            },
            update(id) {
                this.$emit("update", id)
            },
            cellContent(key, item) {
                if (key.attribute) {
                    if (key.attribute == "created_at" && this.isSearchPanel) {
                        let d = new Date(item[key.attribute] * 1000)
                        let dFormat = d
                            .toISOString()
                            .slice(0, 10)
                            .split("-")
                        let res = `${dFormat[2]}.${dFormat[1]}.${dFormat[0]}`
                        return res + " " + d.getHours() + ":" + d.getMinutes()
                    }
                    if (key.attribute == "json_data.title" && this.isSearchPanel) {
                        return item?.json_data?.title || "-//-"
                    }
                    if (key.attribute.includes('.')) {
                        let newKeys = key.attribute.split('.');
                        if (!item[newKeys[0]]) {
                            return "---"
                        }
                        return item[newKeys[0]][newKeys[1]]
                    }
                    if (key.attribute == "is_active") {
                        return !item[key.attribute] ? this.$t('No active') : this.$t('Is active')
                    }
                    return item[key.attribute]
                } else {
                    return key.value(item)
                }
            },
        }
    }
</script>

<style lang="scss" scoped>
    .default-table {
        padding: 15px;
        background-color: white;
        @media (max-width: 992px) {
            overflow-x: scroll;
            white-space: nowrap;
        }

        &.adaptive--md {
            @media screen and (max-width: $md) {
                justify-content: flex-start;
                min-width: $md + 1px;
                overflow-x: auto;
            }
        }

        &.adaptive--lg {
            @media screen and (max-width: $lg) {
                justify-content: flex-start;
                min-width: $lg + 1px;
                overflow-x: auto;
            }
        }
    }

    .table {
        thead {
            th {
                padding: 21px 15px;

                &.table__fieldTh {
                  padding: 10px 15px;

                  &:first-child {
                    padding-left: 0;
                  }
                }
            }
            tr {
                border-style: hidden;
            }
        }

        &__labelTr {
            background-color: $color_lightgray;
        }

        &__label {
            display: flex;
            align-items: center;

            &--pointer {
                cursor: pointer;
            }
        }

        tbody {
            color: #646e78;
            border-top: 1px solid $color_lightgray;

            tr {
                border-color: $color_light;
            }

            td {
                padding: 15px;
                vertical-align: middle;
                line-height: 1.38;
            }
        }
    }
    .actions {
        width: 20%;
    }

    .buttons {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        button {
          transition: .1s;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          height: 42px;

          &:hover {
            background-color: $color_light;
          }
        }

        button span {
            color: $color_blue;
        }
    }

    .pagination {
        margin-top: 30px;
    }

    .empty {
      text-align: center;
      margin-top: 50px;

      &__title {
        font-size: 16px;
      }
    }
    ::v-deep{
        .form-control{
            background-color: white!important;
            border: solid 1px #CAD9F3;
        }
        .mx-input{
            background-color: white!important;
        }
    }
</style>
