<template>
    <transition name="fade-modal">
        <div class="modal" @click="clickModal($event)" v-if="show">
            <div class="modal__content">
                <slot></slot>
                <ModalMini :action="removeModalSuccess" @close="isMiniModalVisible = false"
                    v-if="isMiniModalVisible" :title="$t('Warning')" :subtitle="$t('Do you really want to close the form?')"/>
            </div>
        </div>
    </transition>
</template>

<script>

import ModalMini from "@/components/ui/ModalMini"

export default {
    name: "ModalComponent",
    components: {
        ModalMini
    },
    props: {
        show: {
            type: Boolean,
            required: true
        },
        hideOnClickBackground: {
            type: Boolean,
            required: false,
            default: true
        },
        askBeforeLeave: {
            default: false,
            required: false
        },
        askLeaveAction: {
            required: false
        }
    },

    data() {
        return {
            isMiniModalVisible: false
        }
    },

    watch: {
        askLeaveAction(v) {
            !v.value ? this.$emit('close') : this.isMiniModalVisible = true
        }
    },

    methods: {
        keydownEvent(e) {
            if(e.keyCode === 27) {
                !this.askBeforeLeave ? this.$emit('close') : this.isMiniModalVisible = true
            }
        },
        clickModal(e) {
            let target = e.target.className.replace(/ .*/,'')
            if (target === 'modal'){
                if (!this.askBeforeLeave) {
                    this.hideOnClickBackground ? this.$emit('close') : false
                } else {
                    this.isMiniModalVisible = true
                }
            }

        },

        removeModalSuccess() {
            this.isMiniModalVisible = false
            this.hideOnClickBackground ? this.$emit('close') : false
        },
    },

    mounted() {
        document.addEventListener('keydown', this.keydownEvent)
    },

    beforeDestroy() {
        document.removeEventListener('keydown', this.keydownEvent)
    }
}
</script>

<style lang="scss">
    .modal{
        transition: background-color .2s linear, backdrop-filter .2s linear;
        min-height: 100%;
        height: auto;
        position: absolute;
        top: 0;
        left: 0;
        overflow: hidden;
        background-color: #edf3ffb3;
        display: flex !important;
        align-items: center;
        justify-content: center;

        &.fade-modal-enter, &.fade-modal-leave-to {
            background-color: transparent;

            .modal__content {
                transform: translateY(-200px);
                opacity: 0;
            }
        }

        .modal__content {
            position: relative;
            background-color: white;
            padding: 60px 110px;
            min-width: 500px;
            overflow-y: auto;
            max-height: calc(100vh - 120px);
            min-height: 200px;
            transition: transform 0.2s linear, opacity 0.15s linear;

            @media screen and (max-width: $xl) {
                width: 90%;
            }

            @media screen and (max-width: $lg) {
                padding: 60px 30px;
                min-width: auto;
            }
        }
    }
</style>
